






















import Vue from 'vue';
import moment from 'moment';
import { computeSchedule, UiScheduleEvent } from '@/schedule/schedule';
import Talk from '@/components/Talk.vue';

export default Vue.extend({
  name: 'Schedule',
  components: { Talk },
  props: ['schedule'],
  data() {
    return {
      ratio: 3.8,
      width: 230,
      nowBarOffset: 10,
      nowBarUpdaterInterval: null
    };
  },
  created() {
    const v = this as any;
    v.nowBarUpdaterInterval = setInterval(v.updateNowBarOffset, 5 * 60 * 1000);
    v.updateNowBarOffset();
  },
  destroyed() {
    const v = this as any;
    clearInterval(v.nowBarUpdaterInterval);
  },
  computed: {
    combinedSchedule() {
      return computeSchedule(this.schedule);
    },
    containerHeight() {
      const v = this as any;
      const lastTalk = v.combinedSchedule.flatMap((array: UiScheduleEvent[][]) => array)
        .sort((a: UiScheduleEvent, b: UiScheduleEvent) => -moment(a.toTime).diff(moment(b.toTime)))[0];
      return lastTalk.top * v.ratio + lastTalk.height * v.ratio;
    }
  },
  methods: {
    computeStyle(index: number, item: UiScheduleEvent) {
      return {
        height: item.height * this.ratio + 'px',
        top: item.top * this.ratio + 10 + 'px',
        left: this.width * index + 10 + 'px'
      };
    },
    updateNowBarOffset() {
      const v = this as any;
      const firstTalk = v.combinedSchedule.flatMap((array: UiScheduleEvent[][]) => array)
        .sort((a: UiScheduleEvent, b: UiScheduleEvent) => moment(a.toTime).diff(moment(b.toTime)))[0];
      const firstTalkStartDateTime = moment(firstTalk.fromTime, 'YYYY-MM-DD HH:mm');
      const startOfDayAtTodayDate = moment()
        .set('hour', firstTalkStartDateTime.hour())
        .set('minute', firstTalkStartDateTime.minute());

      var result = moment().diff(startOfDayAtTodayDate, 'minute') * v.ratio + 10;
      if (firstTalkStartDateTime.dayOfYear() != moment().dayOfYear()) {
        result = 0;
      }

      if (result < 0) {
        v.nowBarOffset = 10;
      } else if (result > v.containerHeight) {
        v.nowBarOffset = v.containerHeight;
      } else {
        v.nowBarOffset = result;
      }
    }
  }
});
